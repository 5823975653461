import React from "react";
import memoize from "@RHCommerceDev/utils/memoize";
import PDPProductDetails from "@RHCommerceDev/component-pdp-product-details";
import { useProductData } from "./useProductData";
import BaseProductSkeleton from "@RHCommerceDev/skeleton-base-product";
import PDPHeroImage from "@RHCommerceDev/component-pdp-hero-image";
import PDPConfiguratorAndCTA from "@RHCommerceDev/component-pdp-configurator-and-cta";
import isEmpty from "lodash.isempty";

export interface ProductProps {
  productItem: Product;
  isLoading: boolean;
}
const PDP = memoize((props: ProductProps) => {
  const {
    data,
    fullSkuId,
    availableOptions,
    isProductLoading,
    priceRangeDisplay,
    pageContent,
    isColorizable,
    selectedSwatch,
    selectedOptionLabel,
    totalSwatchesLength,
    isSwatchSwitch,
    postalCode,
    changeSwatchImageOnLineItem,
    handleHeroImageOnIndexChange,
    productSize,
    setProductSize
  } = useProductData(props);

  const ProductInfo = {
    width: { name: productSize, sortOrder: 1 },
    product: { name: data?.productDescription, sortOrder: 2 },
    price: { name: data?.displayName, sortOrder: 4 }
  };

  const isDefaultImages = !data?.displayName
    ?.toLowerCase()
    ?.includes("maxwell");

  return (
    <div
      className={`pb-10 ${
        isDefaultImages
          ? props?.productItem?.rhr
            ? "pdp-page-custom-background"
            : ""
          : "relative"
      }`}
    >
      {isProductLoading || isEmpty(data) ? (
        <BaseProductSkeleton />
      ) : (
        <>
          <PDPProductDetails
            data={ProductInfo}
            productData={data}
            className={isDefaultImages ? "mb-5" : "mb-[34%]"}
            priceProps={{
              memberPrice: priceRangeDisplay?.memberPrices?.[0]!,
              topLabel: priceRangeDisplay?.nextGenDrivenOnSale
                ? `${pageContent?.["SALE_STARTING_AT"]}`
                : pageContent?.["STARTING_AT"],
              onSale: priceRangeDisplay?.nextGenDrivenOnSale!,
              skulowestMemberPrice: priceRangeDisplay?.skulowestMemberPrice!
            }}
          />

          <PDPHeroImage
            changeSwatchImageOnLineItem={changeSwatchImageOnLineItem}
            objectFit={"contain"}
            images={data?.alternateImages}
            isDefaultImages={isDefaultImages}
            isColorizable={isColorizable}
            onIndexChange={handleHeroImageOnIndexChange}
            selectedOptionLabel={selectedOptionLabel}
            isRHR={data.rhr}
            isBannerVisible={totalSwatchesLength > 1}
            isSwatchSwitch={isSwatchSwitch}
            saleSwatchId={data?.saleSwatchId}
            selectedSwatch={selectedSwatch}
            imagePresetOverride={data?.imagePresetOverride}
          />
          <PDPConfiguratorAndCTA
            data={data}
            swatchData={data?.swatchData}
            priceRangeDisplay={priceRangeDisplay}
            postalCode={postalCode}
            onZipChange={() => {}}
            onOrderTypeInfo={() => {}}
            fullSkuId={fullSkuId}
            setProductSize={setProductSize}
          />
        </>
      )}
    </div>
  );
});

export default memoize(PDP);
